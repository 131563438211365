<i18n src="@/locales/diabetes.json" />

<template>
  <div class="diabetes">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <p>
        {{ $t('message_1') }}
      </p>
    </b-message>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{ $t('article_p1_1') }}
        </p>
        <p>
          {{ $t('article_p1_2') }}
        </p>
        <ul>
          <li>{{ $t('article_p1_2_li_1') }}</li>
          <li>{{ $t('article_p1_2_li_2') }}</li>
        </ul>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          <span v-html="$t('article_p2_1')" />
        </p>
        <p>
          {{ $t('article_p2_2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>
      {{ $t('h2_title_1') }}
    </ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[2]">
        <p>
          {{ $t('article_p3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_3')">
          {{ $t('more_info_3') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Diabetes',
    components: {
      ImanMoreInfo,
    },
    mixins: [metaMixin],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-implant/basal-implant/comparative/diabetes/diabete-dent.jpg',
            position: 'right',
            alt: 'diabète et dents'
          },
          {
            path: 'dental-implant/basal-implant/comparative/diabetes/implant-dentaire-risque-diabete.jpg',
            position: 'right',
            alt: 'implant dentaire risque diabéte'
          },
          {
            path: 'dental-implant/basal-implant/comparative/diabetes/implant-dentaire-diabetique.jpg',
            position: 'right',
            alt: 'implant dentaire osteoporose sans risque'
          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.2.4.6.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/diabetes/H1-implant-dentaire-diabete-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/diabetes/H1-implant-dentaire-diabete-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/diabetes/H1-implant-dentaire-diabete-m.jpg");
    }
  }
</style>
